import axios from "axios"
import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react"
import { useAuthContext } from "../auth/authContext"

export interface BookmarksContextType {
  bookmarks: Array<any>
  toggleBookmark: Function
  isSaving: boolean
  isVenueBookmarked: Function
  loading: boolean
  refreshBookmarks: Function
}
export const BookmarksContext = createContext<BookmarksContextType>({
  bookmarks: [],
  toggleBookmark: (venueId) => {},
  isSaving: false,
  isVenueBookmarked: (venueId) => false,
  loading: false,
  refreshBookmarks: () => {},
})

export const useBookmarksContext = (): BookmarksContextType =>
  useContext(BookmarksContext) as BookmarksContextType

export const BookmarksContextProvider = ({ children }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [bookmarks, setBookmarks] = useState([])
  const authContext = useAuthContext()

  const refreshBookmarks = useCallback(async () => {
    console.log("refreshBookmarks")
    const url = process.env.NEXT_PUBLIC_API_URL + `/v1/myBookmarks`
    setLoading(true)
    const res = await axios(url)
    if (res.status === 200) {
      setBookmarks(res.data)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    if (authContext.currentUser) {
      refreshBookmarks()
    }
  }, [])
  const toggleBookmark = async (venueId) => {
    if (!authContext.currentUser) {
      console.log("Not authorized")
      return
    }
    try {
      const url = process.env.NEXT_PUBLIC_API_URL + `/v1/bookmarks`
      setIsSaving(true)
      const req: any = {
        userId: authContext.currentUser?.id,
        venueId,
      }
      if (isVenueBookmarked(venueId)) {
        req.remove = true
      }
      const res = await axios({ url, method: "POST", data: req })
      console.log("res", res)
      setIsSaving(false)
      refreshBookmarks()
    } catch (err) {
      console.error("Error sending request")
    }
  }

  const isVenueBookmarked = (venueId) => {
    return bookmarks ? !!bookmarks.find((b) => b.venueId === venueId) : false
  }

  const value = {
    toggleBookmark,
    isVenueBookmarked,
    bookmarks,
    isSaving,
    loading,
    refreshBookmarks,
  }

  return (
    <BookmarksContext.Provider value={value}>
      {children}
    </BookmarksContext.Provider>
  )
}
