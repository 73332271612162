import React from "react"
import Link from "next/link"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner"

const StyledButton = styled.button<any>`
  border-radius: 30px;
  transition: background 120ms ease-in, opacity 120ms ease-in;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid #dadce0;
  white-space: nowrap;
  font-size: 13px;

  opacity: 1;
  padding: 12px 16px 12px 16px;
  letter-spacing: 0.5px;
  background: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  position: relative;

  transition: background 150ms ease-in-out;
  transition: background-color 150ms ease-in;
  user-select: none;

  :focus {
    outline: none;
  }

  &:hover {
    background: #dadce080;
  }

  .LoadingWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${({ primary, submit }) =>
    (primary || submit) &&
    `
    // background: linear-gradient(to left, #fc4c33, #fc792a);
    background: var(--moje-color-magenta);
    color: white;
    border: none;
    &:hover {
        // background: linear-gradient(to left, #c61b03, #bd4903);
        background: #ff3a67; 
        // var(--moje-color-magenta);
      }
    `}

  ${({ secondary }) =>
    secondary &&
    `
    border: 0px;
    color: #667;

    :hover {
      background: #ddd;
    }
    `}

    ${({ small }) =>
    small &&
    `
    font-size: 0.8em;
    font-weight: bold;
    padding: 4px 8px;
    line-height: 1em;
  `}

  

    ${({ large }) =>
    large &&
    `
      font-size: 1.4em;
      font-weight: bold;
      padding: 12px 24px;
      line-height: 1.4em;
    `}

    
    ${({ grouped }) =>
    grouped &&
    `
      margin-left: 6px;
      margin-right: 6px;
    `}

  ${({ textButton }) =>
    textButton &&
    `
    border: 0;
    background: initial;
    color: #333;
    font-weight: 600;
    transition: background 150ms ease-in;

    &:hover {
      background: rgba(50, 50, 100, 0.1);
    }
    `}

    ${({ disabled }) =>
    disabled &&
    ` 
     opacity: 0.8;
  `}


  ${({ yellow }) =>
    yellow &&
    `
    background-color: #fdc600;
    color: #000;
    font-size: 18px;
    border: 0px !important;
    font-weight: 700;
    padding: 10px 50px;
  `}

  @media (max-width: 800px) {
    padding: 4px;
    font-size: 12px;
  }
`

type ButtonProps = {
  primary?: boolean
  secondary?: boolean
  label?: string
  onClick?: Function
  isSaving?: boolean
  submit?: boolean
  width?: any
  style?: any
  icon?: any
  grouped?: boolean
  disabled?: boolean
  small?: boolean
  iconButton?: boolean
  className?: string
  linkTo?: string
  yellow?: boolean
}
export const MojeButton: React.SFC<ButtonProps> = (props) => {
  const inner = (
    <StyledButton
      {...props}
      width={props.width}
      type={props.submit ? "submit" : "button"}
      style={props.style}
    >
      {props.icon && (
        <FontAwesomeIcon
          icon={props.icon}
          size="2x"
          style={{ marginRight: 5, height: 20 }}
        />
      )}
      {props.children ? props.children : ""}
      {props.label ? (
        <span
          style={{
            opacity: props.isSaving ? 0 : 1,
          }}
        >
          {props.label}
        </span>
      ) : (
        ""
      )}
      {props.isSaving && (
        <span className="LoadingWrapper">
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </span>
      )}
    </StyledButton>
  )

  return props.linkTo ? <Link href={props.linkTo}>{inner}</Link> : inner
}
