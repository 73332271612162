import { createGlobalStyle } from "styled-components"

// ;<link
//   href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"
//   rel="stylesheet"
// ></link>
export const MojeGlobalStyle = createGlobalStyle<any>`
  body {
    color: ${(props) => (props.whiteColor ? "white" : "black")};

    background-color: #fdfeff;
    color: var(--clap-color-black);
    display: flex;
    flex-direction: column;
    line-height: 1.42857;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {

    --moje-border-color-light: #ebebeb;
    --primaryColor: red;

    --moje-standard-border: 1px solid #e1e1e1;
    --moje-color-green: #bace0b;

    --moje-color-red:  #D54027;
    --moje-color-magenta: #d52f55;
    --moje-color-pink: #DE416F;
    --moje-color-black: #1F161E;
    --moje-color-black-blue: #0F466E;
    --moje-color-blue-ocean: #30799F;
    --moje-color-blue-light: #0099E1;
    --moje-color-blue-sky:  #8BD7F3;

    --moje-color-orange: #F09B0B;
    --moje-color-yellow:  #FECB10;

    --moje-color-green-dark: #73A93C;
    --moje-color-green-olive: #C4CE2E;
    --moje-color-grey: #B2B1B2;


    --moje-background-gradient-red: linear-gradient(90deg, #D54027 0%, #DE416F 100%);
    --moje-color-primary: #0F466E; /* blue */
    --moje-color-accent-1: #DE416F; /* pinkish */
    --moje-color-accent-2:  #F09B0B; /* orange */


    --moje-blue-gradient-c1: var(--moje-color-blue-ocean);
    --moje-blue-gradient-c2: #0099E1; 
    // #14445d;

    --moje-green-gradient-c1: var(--moje-color-green-dark);
    --moje-green-gradient-c2:#077e18;


    
    --moje-pink-gradient-c1: var(--moje-color-pink);
    --moje-pink-gradient-c2: #d0165a;

    
    --moje-orange-gradient-c1: var(--moje-color-orange);
    --moje-orange-gradient-c2: var(--moje-color-pink);

    --moje-green-gradient-v: linear-gradient(var(--moje-color-green-dark), #5e8437);
    --moje-blue-gradient-v: linear-gradient(var(--moje-color-blue-ocean),#14445d);

    --moje-green-gradient-h: linear-gradient(to right, var(--moje-color-green-dark), #5e8437);
    --moje-blue-gradient-h: linear-gradient(to right, var(--moje-color-blue-ocean),#14445d);
    
    --moje-green-gradient-v: linear-gradient(var(--moje-color-green-dark), #5e8437);
    --moje-blue-gradient-v: linear-gradient(var(--moje-color-blue-ocean),#14445d);

    
    /* Header */
    --moje-header-height-web: 60px;
    --moje-site-width: 1128px;

    @media(max-width: 600px) {
      --moje-site-width: 100%;
    }

  }


`
