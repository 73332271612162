import { Auth } from "aws-amplify"
import { Component } from "react"
import { MButton } from "../../components/MButton"

function waitForInit() {
  return new Promise<void>((res, rej) => {
    const hasFbLoaded = () => {
      var w: any = window
      if (w.FB) {
        res()
      } else {
        setTimeout(hasFbLoaded, 300)
      }
    }
    hasFbLoaded()
  })
}

export default class FacebookButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  async componentDidMount() {
    await waitForInit()
    this.setState({ isLoading: false })
  }

  statusChangeCallback = (response) => {
    console.log("statusChangeCallback.", response.authResponse)
    if (response.status === "connected") {
      this.handleResponse(response.authResponse)
    } else {
      this.handleError(response)
    }
  }

  checkLoginState = () => {
    ;(window as any).FB.getLoginStatus(this.statusChangeCallback)
  }

  handleClick = () => {
    console.log("handleClick login FB")
    ;(window as any).FB.login(this.checkLoginState, {
      scope: "public_profile,email",
    })
  }

  handleError(error) {
    console.error("FB login error")
    // alert(error)
  }

  async handleResponse(data) {
    console.log("handle FbLogin Response", data)
    const { email, accessToken: token, expiresIn } = data
    const expires_at = expiresIn * 1000 + new Date().getTime()
    const user = { email, name: data.name }

    this.setState({ isLoading: true })
    var a: any = Auth
    a.federatedSignIn({
      provider: "Facebook",
    })
    try {
      // const response = await Auth.federatedSignIn(
      //   "facebook",
      //   { token, expires_at },
      //   user
      // )
      this.setState({ isLoading: false })
      // this.props.onLogin(response)
    } catch (e) {
      this.setState({ isLoading: false })
      this.handleError(e)
    }
  }

  render() {
    return (
      <MButton
        label="Zaloguj z Facebook"
        fullWidth
        onClick={this.handleClick}
        style={{
          backgroundColor: "#3b5998",
          color: "white",
          padding: "12px 16px",
        }}
      />
    )
  }
}
