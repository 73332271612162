import { useAuthContext } from "./authContext"
import { LoginForm } from "./LoginForm"
import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import { TransitionProps } from "@material-ui/core/transitions"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const LoginDialog = () => {
  const authContext = useAuthContext()
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up("md"))
  const onClose = () => authContext.toggleLoginDialog(false)
  return (
    <Dialog
      aria-labelledby="login-dialog-title"
      open={authContext.loginVisible}
      onClose={onClose}
      TransitionComponent={Transition}
      fullScreen={!desktop}
      maxWidth="md"
    >
      <DialogTitle
        id="login-dialog-title"
        disableTypography
        className="flex justify-between items-center pb-3"
      >
        <h6 className="text-2xl font-bold">Zaloguj się</h6>
        <button className="cursor-pointer" onClick={onClose} aria-label="close">
          <svg
            className="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </button>
      </DialogTitle>
      <div className="py-4 px-6">
        <LoginForm />
      </div>
    </Dialog>
  )
}
