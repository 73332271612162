/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-central-1",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_TfARXO8oi",
  aws_user_pools_web_client_id: "65rbu518ds8e678ds26jqnjb3d",
  oauth: {
    scope: [
      "aws.cognito.signin.user.admin",
      "email",
      "openid",
      "phone",
      "profile",
    ],
    redirectSignIn: "https://www.mojeurodziny.pl",
    redirectSignOut: "https://www.mojeurodziny.pl",
    responseType: "code",
    domain: "moje-urodziny.auth.eu-central-1.amazoncognito.com",
  },
  federationTarget: "COGNITO_USER_POOLS",
}

export default awsmobile
