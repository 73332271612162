import { Auth, Hub } from "aws-amplify"
import axios from "axios"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"

export interface CurrentUser {
  id: string
  name: string
  phone?: string
  phoneConfirmed?: boolean
  unreadMessagesCount?: number
}

export interface AuthContextType {
  currentUser?: CurrentUser
  logout: Function
  refetchCurrentUser: Function
  cognitoUser?: any
  initializationCompleted: boolean
  toggleLoginDialog: Function
  loginVisible: boolean
  redirectPath: string
  setRedirectPath: Function
}

const LOCAL_STORAGE_KEY = "mojeAuthContext"

export const AuthContext = createContext<AuthContextType>({
  logout: () => {},
  refetchCurrentUser: () => {},
  initializationCompleted: false,
  toggleLoginDialog: () => {},
  loginVisible: false,
  redirectPath: null,
  setRedirectPath: () => {},
})

export const AuthContextProvider = ({
  children,
  user,
}: {
  children: any
  user?: CurrentUser
}) => {
  const [currentUser, setCurrentUser] = useState(user)
  const [currentCognitoUser, setCurrentCognitoUser] = useState<any>(undefined)
  const [
    isInitializationCompleted,
    setIsInitializationCompleted,
  ] = useState<boolean>(false)
  const [loginVisible, setLoginVisible] = useState(false)
  const [redirectPath, setRedirectPath] = useState(null)
  const [beforeLoginPath, setBeforeLoginPath] = useState<any>()

  const _rehydrate = async () => {
    if (window.localStorage) {
      const dataStr = window.localStorage.getItem(LOCAL_STORAGE_KEY)
      if (dataStr != null) {
        const data = JSON.parse(dataStr)
        if (data.currentUser) {
          setCurrentUser(data.currentUser)
          return data.currentUser
        }
      }
    }
  }
  useEffect(() => {
    // Access the user session on the client
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        const rehydrated = await _rehydrate()
        // const currentUser = await refetchCurrentUser()
        setIsInitializationCompleted(true)
        refetchCurrentUser()
      })
      .catch((err) => {
        setCurrentUser(null)
        setIsInitializationCompleted(true)
      })
  }, [])

  useEffect(() => {
    let onCognitoAuthEvent = (event) => {
      if (event && event) {
        recheckCognitoUser()
      }
    }
    Hub.listen("auth", onCognitoAuthEvent) // listen for login/signup events
    recheckCognitoUser()
    return () => Hub.remove("auth", onCognitoAuthEvent) // cleanup
  }, [])

  const logout = () => {
    Auth.signOut()
    setCurrentUser(undefined)
  }

  const handleToggleLoginDialog = (val) => {
    setLoginVisible(val)
  }
  const _persist = useCallback(() => {
    if (window.localStorage) {
      if (currentUser) {
        const data = {
          currentUser,
        }
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
      } else {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY)
      }
    }
  }, [currentUser])

  useEffect(() => {
    if (isInitializationCompleted) {
      _persist()
    }
  }, [currentUser, isInitializationCompleted])

  const recheckCognitoUser = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser()
      if (user) {
        setCurrentCognitoUser(user)
      }
    } catch (e) {
      setCurrentCognitoUser(undefined)
    }
  }

  // const getMe2 = async () => {
  //   console.log("getMe2")
  //   let meUrl = process.env.NEXT_PUBLIC_API_URL + `/me2`
  //   var result = await axios({
  //     method: "POST",
  //     url: meUrl,
  //   })

  //   console.log("result", result)
  // }

  const refetchCurrentUser = useCallback(async () => {
    let meUrl = process.env.NEXT_PUBLIC_API_URL + `/me`
    if (!currentCognitoUser) {
      return
    }
    try {
      // await getMe2()
      var result = await axios({
        method: "GET",
        url: meUrl,
      })
      if (result.status == 200) {
        setCurrentUser(result.data)
      } else {
        setCurrentUser(undefined)
      }
    } catch (err) {
      console.error("Error refetching curent user")
    }
  }, [currentCognitoUser])

  useEffect(() => {
    // console.log(
    //   "on currentCognitoUser changed effect currentCognitoUser",
    //   currentCognitoUser
    // )
    refetchCurrentUser()
  }, [currentCognitoUser])

  var finalCurrentUser: any = undefined
  if (currentCognitoUser) {
    finalCurrentUser = {}
    finalCurrentUser.id = currentCognitoUser.attributes.sub
    finalCurrentUser.name = currentCognitoUser.attributes.name
    finalCurrentUser.provider = "facebook"
  }
  if (currentUser) {
    finalCurrentUser = { ...currentUser, finalCurrentUser }
  }

  if (
    finalCurrentUser &&
    !finalCurrentUser.name &&
    currentCognitoUser &&
    currentCognitoUser.attributes?.email
  ) {
    finalCurrentUser.name = currentCognitoUser.attributes?.email
  }
  const contextValue = {
    logout,
    currentUser: finalCurrentUser,
    recheckCognitoUser,
    refetchCurrentUser,
    cognitoUser: currentCognitoUser,
    initializationCompleted: isInitializationCompleted,
    toggleLoginDialog: handleToggleLoginDialog,
    loginVisible,
    redirectPath,
    setRedirectPath: (path) => setRedirectPath(path),
  }
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAuthContext = (): AuthContextType =>
  useContext(AuthContext) as AuthContextType
