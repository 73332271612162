import { Formik } from "formik"
import { useRouter } from "next/router"
import { useState } from "react"
import { MojeTextInput } from "../../components/MojeTextInput"

import { MojeButton } from "../../components/ui/MojeButton"
import { AuthContextType, useAuthContext } from "./authContext"
import FacebookButton from "./FacebookLogin"

export type LoginError = {
  code: string
  message: string
  name: string
}
export const LoginByEmailForm = (props: any) => {
  const [loginData, setLoginData] = useState<any>({})
  const [loginError, setLoginError] = useState<LoginError | undefined>(
    undefined
  )
  const router = useRouter()
  const authContext: AuthContextType = useAuthContext()

  const { isLoggingIn, onRegisterByEmail, onSignInByEmail } = props

  const handleSubmit = async (values, props) => {
    console.log("handleSubmit", values, loginMode)
    let user
    try {
      if (loginMode === "signup") {
        user = await onRegisterByEmail(values, props)
      } else if (loginMode === "signin") {
        user = await onSignInByEmail(values, props)
        if (user) {
          await authContext.refetchCurrentUser()
          authContext.toggleLoginDialog(false)
          if (authContext.redirectPath) {
            router.push(authContext.redirectPath)
            authContext.setRedirectPath(null)
          }
        }
      }
    } catch (err) {
      console.error("Signin error", err)
      console.log("Setting login error")
      setLoginError(err)
      setTimeout(() => {
        setLoginError(undefined)
      }, 10000)
    }
  }

  const handleClickForgotPwd = () => {
    props.onClickResetPassword()
  }

  const { loginMode } = props
  const confirmationLabel =
    loginMode === "confirmation"
      ? "Potwierdź"
      : loginMode === "signin"
      ? "Zaloguj"
      : "Zarejestruj"
  return (
    <div>
      <Formik initialValues={loginData} onSubmit={handleSubmit}>
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="py-2">
              <MojeTextInput
                type="text"
                id="email"
                name="email"
                placeholder="E-mail"
                required
                input={{
                  onChange: handleChange,
                  value: values.email,
                }}
              />
            </div>
            <div className="py-2">
              <MojeTextInput
                type="password"
                placeholder="Hasło"
                name="password"
                id="password"
                required
                input={{
                  onChange: handleChange,
                  value: values.password,
                }}
              />
            </div>
            {loginMode === "signin" && (
              <div style={{ padding: "8px 0", textAlign: "right" }}>
                <a href="#" onClick={handleClickForgotPwd}>
                  Zapomniałeś hasła?
                </a>
              </div>
            )}
            {loginError && (
              <div className="text-center text-red-500 h-7 p-3">
                {loginError.message}
              </div>
            )}
            <div style={{ marginTop: 20, textAlign: "right" }}>
              <MojeButton
                primary
                isSaving={isLoggingIn}
                style={{
                  width: "100%",
                  borderRadius: 4,
                  backgroundColor: "#ff8204",
                  color: "white",
                }}
                label={confirmationLabel}
                submit
              />
            </div>
          </form>
        )}
      </Formik>
      <div className="my-1 text-center font-small">LUB</div>
      <div style={{ width: "100%", marginTop: 6 }}>
        <FacebookButton />
      </div>
    </div>
  )
}
