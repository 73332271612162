import {
  FormControl,
  InputBase,
  InputLabel,
  TextareaAutosize,
} from "@material-ui/core"
import { fade, withStyles } from "@material-ui/core/styles"
import React from "react"

// const MojeTextInputStyled = styled.div`
//   box-sizing: border-box;
//   margin: 0;
//   vertical-align: middle;
//   position: relative;

//   &--borderless {
//     border: 0;
//   }

//   &--small {
//     padding: 0 8px;
//     font-size: 14px;
//   }

//   color: rgb(75, 75, 75);
//   background-color: rgb(250, 250, 250);
//   border-color: rgb(225, 225, 225);

//   input {
//     box-sizing: border-box;
//     width: 100%;
//     height: 40px;
//     color: rgba(0, 0, 0, 0.88);
//     border-radius: 4px;
//     border: none;
//     padding: 8px;
//     line-height: 20px;
//     font-weight: 400;
//     font-size: 16px;
//     outline: none !important;
//     font-family: "Nunito Sans", sans-serif;
//     transition: box-shadow 0.15s;
//     box-shadow: 0 0 0 2px transparent inset, 0 0 0 1px #e0e0e0 inset;
//     :focus {
//     }
//   }
//   &:hover {
//     color: rgb(44, 44, 44);
//     background-color: rgb(255, 255, 255);
//     border-color: rgb(202, 202, 202);
//   }
//   transition: background 130ms ease-out, border-color 130ms ease-out,
//     color 130ms ease-out, box-shadow 130ms ease-out;

//   &-modifiedMarker {
//     position: absolute;
//     right: 5px;
//     top: 0;
//     bottom: 0;
//     width: 20px;
//     display: flex;
//     align-items: center;
//     color: #666;
//   }
//   &-required {
//     left: 3px;
//     top: 3px;
//     position: absolute;
//   }
//   &-placeholder {
//   }
// `

export interface MojeTextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name?: string
  input?: any
  placeholder?: any
  onBlur?: (...args: any[]) => any
  small?: boolean
  inputStyle?: any
  type?: any
  iconName?: any
  focus?: any
  disabled?: boolean
  onChange?: any
  onFocus?: any
  value?: any
  required?: any
  id?: any
  readOnly?: any
  maxLength?: any
  ref?: any
  number?: boolean
}

export const BootstrapInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#f5f5f5",
    border: "0px solid #ced4da",
    fontSize: 16,
    // width: "auto",
    padding: "10px 12px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase)

export const MTextArea = withStyles((theme) => ({
  root: {
    width: "100%",
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  textarea: {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    // width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(TextareaAutosize)

export const MojeTextInput: React.FC<any> = (props) => {
  const { input, onChange, ref, id, ...otherProps } = props

  // const classes = useStyles()
  const handleChange = (event: any) => {
    if (props.number) {
      let numberValue = event.target.value
      if (
        numberValue != undefined &&
        numberValue != "" &&
        !Number(numberValue)
      ) {
        return
      }
    }
    event.persist()
    if (input && input.onChange) {
      input.onChange(event)
    }
    if (onChange) {
      onChange(event)
    }
  }
  return (
    // <MojeTextInputStyled>
    <FormControl fullWidth>
      {props.label && (
        <InputLabel shrink htmlFor={id || "bootstrap-input"}>
          {props.label}
        </InputLabel>
      )}
      <BootstrapInput
        ref={ref}
        {...otherProps}
        onChange={handleChange}
        id={id || "bootstrap-input"}
      />
    </FormControl>
    // {/* // </MojeTextInputStyled> */}
  )
}
