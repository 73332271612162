import "react-image-gallery/styles/css/image-gallery.css"

import { useEffect } from "react"
import Amplify, { Auth } from "aws-amplify"
import axios from "axios"
import Head from "next/head"
import { ThemeProvider } from "@material-ui/core"
import { StylesProvider } from "@material-ui/core/styles"
import { IntlProvider } from "react-intl"
// import { GlobalStyles } from "twin.macro"
import configProd from "../aws-exports-prod"
import configDev from "../aws-exports-dev"
import { AuthContextProvider } from "../modules/auth/authContext"
import { ReservationContextProvider } from "../modules/reservations/reservationContext"
import { BookmarksContextProvider } from "../modules/bookmarks/BookmarksContext"
import { MojeGlobalStyle } from "../theme/moje_global_style"
import "../styles/globals.css"
import "animate.css"
import { LoginDialog } from "../modules/auth/LoginDialog"
import { theme } from "../theme/theme"

const config = process.env.NODE_ENV == "development" ? configDev : configProd
Amplify.configure({
  // Auth: {
  //   region: process.env.USER_POOL_REGION,
  //   userPoolId: process.env.USER_POOL_ID,
  //   userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,

  //   // Configuration for cookie storage
  //   // see https://aws-amplify.github.io/docs/js/authentication
  //   cookieStorage: {
  //     // REQUIRED - Cookie domain
  //     // This should be the subdomain in production as
  //     // the cookie should only be present for the current site
  //     domain: process.env.AUTH_COOKIE_DOMAIN,
  //     // OPTIONAL - Cookie path
  //     path: "/",
  //     // OPTIONAL - Cookie expiration in days
  //     expires: 7,
  //     // OPTIONAL - Cookie secure flag
  //     // Either true or false, indicating whether the cookie
  //     // transmission requires a secure protocol (https).
  //     // The cookie should be set to secure in production.
  //     secure: false,
  //   },
  // },
  ...config,
  ssr: true,
})

// Auth.configure({
//   oauth: {
//     domain: process.env.IDP_DOMAIN,
//     scope: ["email", "openid"],
//     // Where users get sent after logging in.
//     // This has to be set to be the full URL of the /token page.
//     redirectSignIn: process.env.REDIRECT_SIGN_IN,
//     // Where users are sent after they sign out.
//     redirectSignOut: process.env.REDIRECT_SIGN_OUT,
//     responseType: "token",
//   },
// })

axios.interceptors.request.use(async (config) => {
  try {
    if (await Auth.currentAuthenticatedUser()) {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken()
      // const token = store.getState().session.token;
      if (token) {
        config.headers.Authorization = "Bearer " + token
      }
    }
  } catch (err) {}

  return config
})

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side")
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <>
      <Head>
        {/* <script
          defer
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD5PDPHdjtQG68Nr3ao2tQi2s8gtGsCE7Q&libraries=places&callback=initMap"
        ></script> */}
      </Head>
      {/* <GlobalStyles /> */}
      <IntlProvider locale="pl" defaultLocale="pl">
        <StylesProvider injectFirst>
          {/* <ThemeProvider theme={theme}> */}
          <AuthContextProvider>
            <ReservationContextProvider>
              <BookmarksContextProvider>
                <MojeGlobalStyle />
                <Component {...pageProps} />
                <LoginDialog />
                {/* <MojeFooter /> */}
              </BookmarksContextProvider>
            </ReservationContextProvider>
          </AuthContextProvider>
          {/* </ThemeProvider> */}
        </StylesProvider>
      </IntlProvider>
    </>
  )
}
export default MyApp
