import React, { useState } from "react"
import { Formik } from "formik"
import { Auth } from "aws-amplify"
import { AuthContextType, useAuthContext } from "./authContext"
import { MButton } from "../../components/mojeui/MButton"
import { MojeTextInput } from "../../components/MojeTextInput"

export const ConfirmationForm = (props: any) => {
  const [loginData, setLoginData] = useState<any>({
    username: props.confirmationUsername,
  })
  const [confirmationResult, setConfirmationResult] = useState<any>(undefined)

  const [loginError, setLoginError] = useState(undefined)
  const authContext: AuthContextType = useAuthContext()
  const handleRegister = () => {
    console.log("handleRegister")
  }

  // console.log('registration form ,', authContext, props)
  const { isLoggingIn, onConfirmed } = props

  const handleConfirm = async (values, { setSubmitting }) => {
    console.log("handleConfirm")
    try {
      const res = await Auth.confirmSignUp(
        values.username,
        values.confirmation_code
      )
      console.log("Confirmation result", res)
      if (res === "SUCCESS") {
        console.log("Email potwierdzony")
        // Alert("Email potwierdzony!")
        onConfirmed()
      }
    } catch (error) {
      console.log("Confirmation error" + error)
      setConfirmationResult(error)
    }
    // console.log('Confirm result: ', res)
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("handleSubmit", values)
    let user
    try {
      setSubmitting(true)
      const res = await handleConfirm(values, { setSubmitting })
      setSubmitting(false)
    } catch (err) {
      console.log("confirmation error", err)
      console.log(err)
      setLoginError(err.message)
      setSubmitting(false)
      setTimeout(() => {
        setLoginError(undefined)
      }, 5000)
    }
  }

  const handleRequestCode = (username) => {
    console.log("handleRequestCode")
    try {
      Auth.resendSignUp(username)
    } catch (err) {
      console.error("Can't request codes")
    }
  }

  const { loginMode } = props
  const errorMessage = undefined
  return (
    <div className="py-2">
      <Formik initialValues={loginData} onSubmit={handleSubmit}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          submitForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="py-2">
              <MojeTextInput
                type="username"
                placeholder="Telefon / e-mail "
                name="username"
                id="username"
                required
                input={{
                  onChange: handleChange,
                  value: values.username,
                }}
              />
            </div>

            <div className="py-2">
              <MojeTextInput
                type="text"
                id="confirmation_code"
                name="confirmation_code"
                placeholder="Kod potwierdzający"
                required
                input={{
                  onChange: handleChange,
                  value: values.confirmation_code,
                }}
              />
            </div>

            <div className="pt-2">
              {errorMessage && (
                <span className="alert alert-danger help-block">
                  {errorMessage}
                </span>
              )}

              <MButton
                color="primary"
                onClick={async () => {
                  submitForm()
                }}
                isSaving={isSubmitting}
                style={{
                  width: "100%",
                  borderRadius: 4,
                  backgroundColor: "#ff8204",
                  color: "white",
                }}
                label="Potwierdź adres"
              />
            </div>
            {loginError && (
              <div
                style={{
                  textAlign: "center",
                  padding: 10,
                  height: 30,
                  color: "red",
                }}
              >
                {loginError}
              </div>
            )}

            <React.Fragment>
              <MButton
                onClick={() => handleRequestCode(values.username)}
                label="Wyślij kod ponownie"
              />
            </React.Fragment>
          </form>
        )}
      </Formik>

      {confirmationResult?.message}
    </div>
  )
}
