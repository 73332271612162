import axios from "axios"
import { nanoid } from "nanoid"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"

import { useAuthContext } from "../auth/authContext"

export interface Reservation {
  id: string
  reservationDate?: string
  reservationTime?: string
  dayOfWeek?: string
  venue?: any
  product?: any
  kidsCount?: number

  baseRate?: number
  additionalPersonRate?: number

  additionalGuestsCount?: number
  additionalPrice?: number
  totalPrice?: number

  customerRemarks?: string
}

export interface ReservationContextType {
  reservation?: Reservation
  setReservationDate?: Function
  setReservationVenue?: Function
  makeReservation: Function
  sendSimpleRequest: Function
  setProduct?: Function
  isSaving?: boolean

  updateReservation?: Function
  getNewReservationUrl: Function
}

const LOCAL_STORAGE_KEY = "mojeReservation"
export const ReservationContext = createContext<ReservationContextType>({
  getNewReservationUrl: () => {},
  makeReservation: () => {},
  sendSimpleRequest: () => {},
})

export const ReservationContextProvider = ({ children }) => {
  const [reservation, setReservation] = useState(undefined)
  const [isSaving, setIsSaving] = useState(false)
  const authContext = useAuthContext()

  const _rehydrate = async () => {
    console.log("ReservationContextProvider._rehydrate")
    if (window.localStorage) {
      const dataStr = window.localStorage.getItem(LOCAL_STORAGE_KEY)
      if (dataStr != null) {
        const data = JSON.parse(dataStr)
        if (data.reservation) {
          setReservation(data.reservation)
          return data.currentUser
        }
      }
    }
  }

  useEffect(() => {
    // Access the user session on the client
    console.log("ReservationContext Initialization")
    _rehydrate()
    //   Auth.currentAuthenticatedUser()
    //     .then(async (user) => {
    //       console.log("User: ", user)
    //       const rehydrated = await _rehydrate()
    //       console.log("rehydrated", rehydrated)
    //       // const currentUser = await refetchCurrentUser()
    //       setIsInitializationCompleted(true)
    //       refetchCurrentUser()
    //     })
    //     .catch((err) => {
    //       console.log("check cognito current user err")
    //       console.log(err)
    //       setCurrentUser(null)
    //       setIsInitializationCompleted(true)
    //     })
  }, [])

  const _persist = useCallback(() => {
    if (window.localStorage) {
      if (reservation) {
        const data = {
          reservation,
        }
        console.log("ReservationContext .persist")
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
      } else {
        window.localStorage.removeItem(LOCAL_STORAGE_KEY)
      }
    }
  }, [reservation])

  useEffect(() => {
    console.log("ReservationContext Persister")
    _persist()
  }, [reservation])

  const setReservationDate = (newDate) => {
    console.log("setReservationDate")
    recalculateAndSet({ ...reservation, reservationDate: newDate })
  }
  const setReservationVenue = (venue) => {
    console.log("setReservationVenue")
    recalculateAndSet({ ...reservation, venue: venue })
  }

  const setProduct = (product, venue) => {
    console.log("setProduct")
    recalculateAndSet({ ...reservation, product, venue })
  }
  const updateReservation = (newParams) => {
    console.log("updateReservation", reservation, newParams)
    const newReservation = reservation ?? {}

    recalculateAndSet({ ...newReservation, ...newParams })
  }
  const recalculateAndSet = (reservation) => {
    console.log("recalculateAndSet", reservation)
    if (reservation && reservation.product && reservation.product.pricing) {
      console.log("recalculateAndSet", reservation.product.pricing)
      if (reservation.product.pricing.length > 0) {
        var calculatedPrice = reservation.product.pricing[0].price
        var basePricePerPax = 10
        var additionalGuestsCount =
          reservation.kidsCount > basePricePerPax
            ? reservation.kidsCount - basePricePerPax
            : 0
        console.log("additionalGuestsCount", additionalGuestsCount)
        reservation.additionalGuestsCount = Number(additionalGuestsCount)
        reservation.baseRate = Number(calculatedPrice)
        reservation.additionalPersonRate =
          reservation.product.pricing[0].additionalPersonPrice ?? 0

        reservation.additionalPrice =
          additionalGuestsCount *
          (reservation.product.pricing[0].additionalPersonPrice ?? 0)
        reservation.totalPrice =
          reservation.baseRate + reservation.additionalPrice
      }
      setReservation(reservation)
    } else {
      setReservation(undefined)
    }
  }

  const getNewReservationUrl = () => {
    const params = []

    if (!reservation?.venue) {
      return undefined
    }
    if (reservation.product) {
      params.push(`productId=${reservation.product.id}`)
    }
    return `/reserve/venue/${reservation.venue.id}?${params.join("&")}`
  }
  const sendSimpleRequest = async (venue, requestText) => {
    console.log("sendSimpleRequest", requestText)
    const nextReservationId = nanoid(10)
    try {
      const placeReservationCommand = {
        type: "Reservation.Request",
        aggregateId: nextReservationId,
        payload: {
          type: "SIMPLE_REQUEST",
          requestText,
          user: {
            id: authContext.currentUser.id,
            name: authContext.currentUser.name,
          },
          merchant: {
            id: venue.merchantId,
            name: venue.merchant?.name,
          },
        },
      }
      const url =
        process.env.NEXT_PUBLIC_API_URL +
        `/v1/reservations/${nextReservationId}`
      const result = await axios.post(url, placeReservationCommand)
      console.log("sendSimpleRequest Result", result)
      setIsSaving(false)
      return result.data
    } catch (err) {
      console.error("Error doing reservation")
    }
  }
  const makeReservation = useCallback(async () => {
    console.log("makeReservation", reservation, authContext)
    if (!authContext.currentUser) {
      throw new Error("No authorized")
    }
    setIsSaving(true)
    // Keep it for testing
    // return new Promise<any>((resolve) => {
    //   setTimeout(() => {
    //     setIsSaving(false)
    //     setReservation(undefined)
    //     resolve({
    //       success: true,
    //     })
    //   }, 1500)
    // })

    const initialConditions = {
      ...reservation,
      // kidsCount: reservation.kidsCount,
      // additionalGuestsCount: reservation.additionalGuestsCount,
      // baseRate: reservation.baseRate,
      // additionalPersonRate: reservation.additionalPersonRate,
      // totalPrice: reservation.totalPrice,
    }
    delete initialConditions.venue
    delete initialConditions.merchant
    delete initialConditions.user
    const nextReservationId = nanoid(10)
    const placeReservationCommand = {
      type: "Reservation.Request",
      aggregateId: nextReservationId,
      payload: {
        ...reservation,
        user: {
          id: authContext.currentUser.id,
          name: authContext.currentUser.name,
        },
        merchant: {
          id: reservation.venue.merchantId,
          name: reservation.venue.merchant?.name,
        },
        productId: reservation.product?.id,
        initialConditions,
      },
    }

    try {
      const url =
        process.env.NEXT_PUBLIC_API_URL +
        `/v1/reservations/${nextReservationId}`
      const result = await axios.post(url, placeReservationCommand)
      console.log("makeReservationResult", result)
      setIsSaving(false)
      return result.data
    } catch (err) {
      console.error("Error doing reservation")
    }
    setIsSaving(false)
  }, [authContext])

  const contextValue = {
    reservation,
    setReservationDate,
    setReservationVenue,
    makeReservation,
    isSaving,
    setProduct,
    updateReservation,
    sendSimpleRequest,
    getNewReservationUrl,
  }
  return (
    <ReservationContext.Provider value={contextValue}>
      {children}
    </ReservationContext.Provider>
  )
}

export const useReservationContext = (): ReservationContextType =>
  useContext(ReservationContext) as ReservationContextType
