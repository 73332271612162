import { useState } from "react"

import { MojeButton } from "../../components/ui/MojeButton"
import { ConfirmationForm } from "./ConfirmationForm"
import { LoginByEmailForm, LoginError } from "./LoginByEmailForm"
import { Auth } from "aws-amplify"
import { Box } from "@material-ui/core"

function translateErrorMessage(loginError: LoginError) {
  if (loginError.code == "UsernameExistsException") {
    return "To konto już istnieje"
  }
  return loginError.message
}
const LOGIN_STEP_EMAIL = "EMAIL"
const LOGIN_STEP_PASSWORD = "PASSWORD"
export const LoginForm = () => {
  const [loginMode, setLoginMode] = useState("signin")
  const [loginStep, setLoginStep] = useState(LOGIN_STEP_EMAIL)
  const [isRedirectedToSignIn, setIsRedirectedToSignIn] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [loginError, setLoginError] = useState<LoginError | undefined>(
    undefined
  )

  const [error, setError] = useState(undefined)

  var translatedErrorMessage = loginError
    ? translateErrorMessage(loginError)
    : undefined
  console.log("translatedErrorMessage", translatedErrorMessage)
  const handleSignInByEmail = async (values) => {
    setIsSaving(true)
    try {
      const user = await Auth.signIn(values.email, values.password)
      setIsSaving(false)
      return user
    } catch (err) {
      setIsSaving(false)
      setError(err)
      throw err
    }
  }

  const handleClickResetPassword = () => {
    setLoginMode("resetPassword")
  }

  const handleRegisterByEmail = async (values) => {
    setIsSaving(true)
    console.log("handleRegisterByEmail")
    try {
      const res = await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          preferred_username: values.email,
          email: values.email,
          name: values.email,
        },
      })

      setIsSaving(false)

      if (res.userSub && res.userConfirmed === false) {
        // we got user - redirect to confirmation page
        setLoginMode("confirmation")
        // setConfirmationUsername(values.phone)
      }
    } catch (err) {
      console.error("Register exception", err)
      setIsSaving(false)
      // This user already exists so we redirect to log-in (like airBbnb)
      if (err.code === "UsernameExistsException") {
        console.log("This account already exists - log in instead")
        setLoginMode("signin-redirect")
      } else {
        setLoginError(err)
      }
    }
  }

  return (
    <div className="flex" style={{ minHeight: 300 }}>
      <div className="w-full rounded-3xl flex flex-col">
        <div className="p-5">
          {loginMode === "signup" && <div>Załóż konto rodzica za DARMO</div>}
          {loginMode === "confirmation" && (
            <div className="py-2">Potwierdź e-mail</div>
          )}
          {loginMode === "confirmation" && (
            <ConfirmationForm
              onConfirmed={() => {
                console.log("On confirmed")
                setLoginMode("signin")
              }}
            />
          )}
          {(loginMode == "signup" ||
            loginMode == "signin" ||
            loginMode == "signin-redirect") && (
            <>
              {loginMode === "signin-redirect" && (
                <Box py={2}>To konto już istnieje - zaloguj się</Box>
              )}
              <LoginByEmailForm
                loginMode={loginMode}
                onSignInByEmail={handleSignInByEmail}
                onRegisterByEmail={handleRegisterByEmail}
                onClickResetPassword={handleClickResetPassword}
                isLoggingIn={isSaving}
              />
              {translatedErrorMessage && (
                <div className="text-center text-red-500 h-7 p-3">
                  {translatedErrorMessage}
                </div>
              )}
            </>
          )}
          <div style={{ textAlign: "center", marginTop: 10 }}>
            {loginMode == "signin" && (
              <MojeButton
                secondary
                label="Zarejestruj za DARMO"
                onClick={() => setLoginMode("signup")}
              />
            )}

            {loginMode == "signup" && (
              <MojeButton
                secondary
                label="Zaloguj"
                onClick={() => setLoginMode("signin")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
